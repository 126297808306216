import axios from "axios";
import { API_URL, AMBII_API_KEY } from "./index";
import { get } from "lodash";

export async function HTTP(method, uri, data, headers = null, params = null) {
  const url = `${API_URL}${uri}`.trim();
  let query = { method, url, headers: {} };

  if (headers !== null) query.headers = headers;
  if (params !== null) query.params = params;
  if (method === "post" || method === "put" || method === "delete" || method === "patch") query.data = data;

  // Ambii keys
  const ambii_auth_jwt = localStorage.getItem("ambii_auth_jwt");
  const ambii_player_jwt = localStorage.getItem("ambii_player_jwt");

  if (AMBII_API_KEY) query.headers["ambii_api_key"] = AMBII_API_KEY;
  if (ambii_auth_jwt) query.headers["Authorization"] = "Bearer " + localStorage.getItem("ambii_auth_jwt");
  if (ambii_player_jwt) query.headers["ambii_player_jwt"] = ambii_player_jwt;

  return new Promise(function (resolve, reject) {
    axios(query)
      .then((response) => {
        const { data } = response || {};
        console.log(`API Response: ${uri}`, { query, response, data });
        return resolve(data);
      })
      .catch((err) => {
        let type = get(err, "response.data.type", "");
        console.log(`API Error: ${uri}`, { err, type });
        if (type === "TOKEN_INVALID" || type === "TOKEN_EXPIRED") {
          localStorage.removeItem("ambii_player_jwt");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        reject(err);
      });
  });
}
