import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.less";

const DrawerRowItem = ({ className, style, icon, iconStyle, title, titleStyle, extra, onClick }) => (
  <div className={"drawer-row-item ".concat(className ? className : "")} style={style}>
    <div className={"click-wrapper ".concat(extra ? "no-hover" : "")} onClick={onClick}>
      <div className={"row-item-icon"}>
        <FontAwesomeIcon icon={icon} style={iconStyle} />
      </div>
      <div className={"row-item-text noselect"} style={titleStyle}>
        {title}
      </div>
    </div>
    <div className={"row-item-extra"}> {extra} </div>
  </div>
);
export default DrawerRowItem;
