/* eslint-disable no-undef */

import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
// import { createBrowserHistory } from "history";
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
// import { routerMiddleware } from 'react-router-redux';
import rootReducer from "../reducers";
import initialState from "./initialState";
import { SET_IS_PLAYING, SET_SONG_COMPLETION } from "../constants";
// export const history = createBrowserHistory();
// const logger = createLogger({
//   predicate: (getState, action) => action.type !== SET_SONG_COMPLETION && action.type !== SET_IS_PLAYING,
// });
const middlewares = [
  // Add other middleware on this line...
  // thunk middleware can also accept an extra argument to be passed to each thunk action
  // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
  thunk,
  // logger,

  //  reactRouterMiddleware,
];
if (process.env.NODE_ENV === "development") {
  // middlewares.push(logger);
}
export const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)));

export const persistor = persistStore(store);
// eslint-disable-next-line
export default { store, persistor };
