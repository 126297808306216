const localDarkTheme = JSON.parse(localStorage.getItem("darkTheme"));
let localVolume = parseFloat(localStorage.getItem("volume"));
let initialState = {
  location: {},
  stations: [],
  favorites: [],
  settings: {
    mvix: false, // Is the user coming from MVIX
  },
  player: {
    showPlayer: false,
    isPlaying: false,
    skipDisabled: false,
    trackNumber: 0,
    trackMap: {},
    playerError: {},
    ismPreview: false,
    playerVolume: localVolume || 0.1,
    messageQueue: [],
    fadeDuration: 1.5, // Duration we want our fade to last
    fadeStretch: 3, // Stretch value of the fade effect
    fadeArray: [], // Array of fade values calculate on fadeOut
    fadeArrayIndexes: [], // Array of indexes used during fadeOut (used with fadeArray)
    songCompletion: 0,
    isAssigningStation: false,
    showSideMenu: false,
    stationToBeAssigned: null,
  },
  messaging: {
    messages: [],
    messageHash: "", // The hash of all current messages
    scheduledMessagesMap: [],
    intervalMessagesMap: [],
  },
  darkTheme: { darkTheme: localDarkTheme || true },
};

export default initialState;
