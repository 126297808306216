import { SET_MESSAGES, SET_MESSAGE_HASH, SET_INTERVAL_MESSAGES_MAP, SET_SCHEDULED_MESSAGES_MAP } from "../constants";
import initialState from "../redux-store/initialState";

const messaging_reducer = (state = initialState.messaging, action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return { ...state, messages: action.data };
    case SET_MESSAGE_HASH:
      return { ...state, messageHash: action.data };
    case SET_SCHEDULED_MESSAGES_MAP:
      return { ...state, scheduledMessagesMap: action.data };
    case SET_INTERVAL_MESSAGES_MAP:
      return { ...state, intervalMessagesMap: action.data };
    default:
      return state;
  }
};

export default messaging_reducer;
