import React, { useEffect, useMemo, useState } from "react";
import { Modal, notification, Spin } from "antd";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { responses } from "../../constants";
import { setIsAssigningStation, setShowSideMenu, setStationToBeAssigned, assignStation } from "../../actions";
import { colors } from "../../styles/colors";
import "./index.less";

const StationTile = ({ name, stationId, locationIds, setDrawerVisiblity }) => {
  const [tileLoading, setTileLoading] = useState(false);
  const { isAssigningStation, currentStation, isMusic } = useSelector((state) => ({ isAssigningStation: state.player.isAssigningStation, skipDisabled: state.player.skipDisabled, currentStation: state.location.station._id, isMusic: state.location.currentTrack.isMusic }));
  const dispatch = useDispatch();
  const isDisabled = useMemo(() => isAssigningStation, [isAssigningStation]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isAssigningStation) {
      const beingAssigned = localStorage.getItem(stationId);
      if (stationId === beingAssigned) setTileLoading(true);
    } else {
      localStorage.removeItem(stationId);
    }
    // eslint-disable-next-line
  }, [isAssigningStation]);

  //functions
  const handleSumbit = async () => {
    if (isDisabled) return;
    setTileLoading(true);
    if (currentStation !== stationId) {
      if (isMusic) {
        localStorage.setItem(stationId, stationId);
        dispatch(assignStation({ stationId, locationIds })).then(() => {
          dispatch(setIsAssigningStation(false));
          dispatch(setShowSideMenu(false));
          setDrawerVisiblity(false);
          setTileLoading(false);
        });
      } else {
        dispatch(setStationToBeAssigned({ stationId, locationIds }));
        setDrawerVisiblity(false);
        dispatch(setShowSideMenu(false));
        notification.success({ message: "Success", description: `This station will be assigned after Ads/Announcement`, placement: "bottomLeft" });
      }
    } else {
      notification.warn({ message: responses.status.warn, description: `You are already on ${name}`, placement: "bottomLeft" });
      setTileLoading(false);
    }
  };

  return (
    <>
      <div className="station-tile-wrapper">
        <div className={`station-tile ${isDisabled && !tileLoading ? "disabled" : ""}`} onClick={() => !isDisabled && setShowModal(true)}>
          <div className="spin-wrapper">{tileLoading && <Spin spinning={true} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}</div>
          <div className="station-name">{name}</div>
        </div>
      </div>
      <Modal
        title={
          <div style={{ fontSize: "18px" }}>
            <ExclamationCircleOutlined style={{ color: colors.warningColor, marginRight: "10px" }} />
            Assign {name}?
          </div>
        }
        visible={showModal}
        onOk={() => {
          setShowModal(false);
          handleSumbit();
        }}
        onCancel={() => setShowModal(false)}
      >
        <div style={{ marginLeft: "30px", padding: 20 }}>
          Are you sure you want to assign this station : <span style={{ fontWeight: "bold" }}> {name} </span>?
        </div>
      </Modal>
    </>
  );
};

export default React.memo(StationTile);
