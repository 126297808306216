import { HTTP } from "../configs";
import { get } from "lodash";
import { version } from "../configs";

export function logError({ name, err }) {
  return function (dispatch, getState) {
    return new Promise((resolve) => {
      if (!name || !err) resolve(false);
      let location = get(getState(), "location", null);
      let dataToSend = {
        platform: "web-player",
        function: name,
        error: JSON.stringify(err),
        version,
        location: get(location, "_id", null),
        player: get(location, "pairedDevice._id", null),
      };

      HTTP("post", "/v3/api/log/error", dataToSend);
      resolve(true);
    });
  };
}
