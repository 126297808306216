import { Modal, notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downVote } from "../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { responses } from "../../constants";
import "./index.less";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

const options = ["I don't like this song", "This song is overplayed", "This song doesn't belong in this station", "Explicit album art"];

const DownVoteModal = ({ setVisible, visible }) => {
  const currentTrack = useSelector((state) => state.location.currentTrack);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [downVoteSelection, setDownVoteSelection] = useState("");
  useEffect(() => {
    setDownVoteSelection("");
  }, [currentTrack]);

  return (
    <Modal
      className="down-vote-modal"
      visible={visible}
      onCancel={() => setVisible(false)}
      title={"Downvote Track?"}
      onOk={async () => {
        setLoading(true);
        if (!downVoteSelection) {
          notification.error({ message: responses.status.error, description: "Please specify the reason.", placement: "bottomLeft" });
          setLoading(false);
          return;
        }

        const reasonNo = options.indexOf(downVoteSelection);
        await dispatch(downVote(reasonNo));
        console.log("Down Vote Selection", downVoteSelection);
        setLoading(false);
        setVisible(false);
      }}
      okText={"Submit"}
      okButtonProps={{ loading, disabled: !downVoteSelection }}
    >
      {options.map((option, index) => (
        <div
          key={index}
          className={`option ${options[index] === downVoteSelection && "selected"}`}
          onClick={() => {
            setDownVoteSelection(option);
          }}
          // onMouseEnter={() => {
          //   setTick(true);
          //   setDownVoteSelection(option);
          // }}
          // onMouseLeave={() => {
          //   setTick(false);

          // }}
        >
          <div className="option-content">
            <div className="text-wrapper">{option}</div>
            <div className="icon-wrapper">
              <FontAwesomeIcon className="tick-icon" icon={faCheck} />
            </div>
          </div>
        </div>
      ))}
    </Modal>
  );
};

export default DownVoteModal;
