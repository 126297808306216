import { SET_SCHEDULE, SET_TRACK_NUMBER, SET_LAST_TRACK, SET_TRACK_SCHEDULE, SET_LOCATION, SET_STATION, SET_CURRENT_TRACK, SET_EMPTY_LOCATION } from "../constants";
import initialState from "../redux-store/initialState";
import { get } from "lodash";
import { objArrayToMap } from "../util";

const location_reducer = (state = initialState.location, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return Object.assign({}, state, { ...action.data, favoritesMap: objArrayToMap(get(action, "data.favorites", []), "_id") });
    case SET_STATION:
      return Object.assign({}, state, { ...state.location, station: action.data });
    case SET_SCHEDULE:
      return Object.assign({}, state, { ...state.location, schedule: action.data });
    case SET_CURRENT_TRACK:
      let isMusic = true;
      if (get(action, "data.isrc", "")?.includes("ADVERT_")) isMusic = false;
      else if (get(action, "data.isrc", "")?.includes("ISM_")) isMusic = false;
      else isMusic = true;
      return Object.assign({}, state, { ...state.location, currentTrack: { ...action.data, isMusic } });
    case SET_LAST_TRACK:
      return Object.assign({}, state, { ...state.location, lastTrack: action.data });
    case SET_TRACK_NUMBER:
      return Object.assign({}, state, { ...state.location, currentTrackNumber: action.data });
    case SET_TRACK_SCHEDULE:
      return Object.assign({}, state, { ...state.location, trackSchedule: action.data });
    case SET_EMPTY_LOCATION:
      return {};
    default:
      return state;
  }
};

export default location_reducer;
