export * from "./actionTypes";
export * from "./analytics";
export * from "./genreColors";
export * from "./genreProperNames";
export * from "./images";
export * from "./messaging";
export * from "./serverResponses";
export * from "./supersetDescriptions";
export * from "./supersets";
export * from "./tempos";

export const envTypes = {
  local: "local",
  dev: "dev",
  prod: "production",
};

export const userTypes = {
  Owner: "Owner",
  Individual: "Individual",
};

// Track volume levels
export const trackVolume = {
  muted: 0,
  low: 0.3,
  medium: 0.6,
  high: 1,
};

export const defaultStationCount = 12;
