import { HTTP } from "../configs";
import { addToTrackMap, setMessageQueue, setMessages, setMessageHash, setIntervalMessagesMap, setScheduledMessagesMap, logError } from "./";
import { get } from "lodash";
import { notification } from "antd";
import { dateFormatter, messageDateFormatter } from "../util";
import { responses } from "../constants";

// Grab location's announcements
export const getAnnouncements = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    let storedMessageHash = get(getState(), "messaging.messageHash", "");
    let locationId = get(getState(), "location._id", "");

    if (!locationId) resolve(false);

    HTTP("get", `/v3/api/announcement/?locationId=${locationId}&hash=${storedMessageHash}`)
      .then((response) => {
        console.log("get announcements: ", { state: getState(), storedMessageHash, response });

        if (get(response, "success", false)) {
          let data = get(response, "data", {});
          let messages = data?.announcements;
          let messageHash = data?.messageHash;
          let scheduledMessageMap = {};
          let intervalMessageMap = {};

          if (messages && messageHash !== storedMessageHash) {
            console.log("hit message res: ", { messages, messageHash, storedMessageHash });

            // Filters the ISMs with specifiedTimes
            let scheduledMessages = messages.filter((value) => value?.specifiedTimes?.length > 0);

            // Find the user's instore messages
            for (const message of messages) {
              const frequency = get(message, "frequency", "");
              if (frequency.toString().length <= 3 && frequency.toString() !== 0) {
                if (!intervalMessageMap[frequency]) intervalMessageMap[frequency] = [message];
                else intervalMessageMap[frequency] = [...intervalMessageMap[frequency], message];
              }
            }

            // Filters the specified ISMs using current day and then converts them array of maps where keys are time
            scheduledMessages.map((item) =>
              item?.specifiedTimes
                .map((item) => messageDateFormatter({ days: item?.days, time: item?.time?.selectedTime.substring(0, 5) }))
                .map((timeArray) =>
                  timeArray.forEach((time) => {
                    // Start - Dev Usage
                    // let date = new Date();
                    // if (time.includes(date.toString().slice(0, 3).toLowerCase())) time = dateFormatter(date, true);
                    // console.log("hit scheduled setup: ", { time, date, item, scheduledMessageMap });
                    // End - Dev Usage

                    // Create array w/ single elem
                    if (!scheduledMessageMap[time]) scheduledMessageMap[time] = [item];
                    // Create array w/ new elem + all previous elems
                    else scheduledMessageMap[time] = [...scheduledMessageMap[time], item];
                  })
                )
            );

            console.log("getAnnouncements: ", { messages, messageHash, intervalMessageMap, scheduledMessageMap });
            dispatch(setMessages(messages));
            dispatch(setMessageHash(messageHash));
            dispatch(setScheduledMessagesMap(scheduledMessageMap));
            dispatch(setIntervalMessagesMap(intervalMessageMap));
          }
        } else notification.error({ message: responses.status.error, description: responses.description.failedToGetAnnouncements, placement: "bottomLeft" });
        resolve(true);
      })
      .catch((err) => {
        console.error("message failed", err);
        dispatch(logError({ name: "getAnnouncements", err }));
        notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
        reject(err);
      });
  });
};

export const addMessageToTrackMap = (message) => {
  return async (dispatch, getState) => {
    try {
      let id = "ISM_".concat(get(message, "_id", ""));
      let track = {
        id,
        isrc: id,
        url: get(message, "url", ""),
        artist: get(getState(), "location.name", "Ambii"),
        title: get(message, "title", "Announcement"),
        picture: "https://s3.us-west-2.amazonaws.com/ambii.io/assets/logo.png",
        duration: get(message, "duration", 0),
      };

      await dispatch(addToTrackMap({ key: id, value: track })); // Add message to TrackMap for reuse
      return track;
    } catch (err) {
      console.log("caught err: ", err);
      return false;
    }
  };
};

export const addMessagesToQueue = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve) => {
      try {
        let date = new Date();
        let formattedDate = dateFormatter(date);
        let scheduleldMessages = getState().messaging?.scheduledMessagesMap[formattedDate];
        let fifteenMinutes = getState().messaging?.intervalMessagesMap["15"];
        let thirtyMinutes = getState().messaging?.intervalMessagesMap["30"];
        let sixtyMintues = getState().messaging?.intervalMessagesMap["60"];
        let fewHours = getState().messaging?.intervalMessagesMap["180"];

        let messageQueue = [];

        // Check for scheduled messages
        if (scheduleldMessages)
          for (const message of scheduleldMessages) {
            let msg = getState().player.trackMap["ISM_".concat(get(message, "_id", ""))];
            if (!msg) msg = await dispatch(addMessageToTrackMap(message));
            messageQueue.push(msg);
          }

        // Check for 15-minute interval messages
        if (fifteenMinutes && date.getMinutes() % 15 === 0)
          for (const message of fifteenMinutes) {
            let msg = getState().player.trackMap["ISM_".concat(get(message, "_id", ""))];
            if (!msg) msg = await dispatch(addMessageToTrackMap(message));
            messageQueue.push(msg);
          }

        // Check for 30-minute interval messages
        if (thirtyMinutes && date.getMinutes() % 30 === 0)
          for (const message of thirtyMinutes) {
            let msg = getState().player.trackMap["ISM_".concat(get(message, "_id", ""))];
            if (!msg) msg = await dispatch(addMessageToTrackMap(message));
            messageQueue.push(msg);
          }

        // Check for 60-minute interval messages
        if (sixtyMintues && date.getMinutes() === 0)
          for (const message of sixtyMintues) {
            let msg = getState().player.trackMap["ISM_".concat(get(message, "_id", ""))];
            if (!msg) msg = await dispatch(addMessageToTrackMap(message));
            messageQueue.push(msg);
          }

        // Check for 180-minute interval messages
        if (fewHours && date.getHours() % 3 === 0 && date.getHours() === 0 && date.getMinutes() === 0)
          for (const message of fewHours) {
            let msg = getState().player.trackMap["ISM_".concat(get(message, "_id", ""))];
            if (!msg) msg = await dispatch(addMessageToTrackMap(message));
            messageQueue.push(msg);
          }
        await dispatch(setMessageQueue([...getState().player.messageQueue, ...messageQueue]));
        resolve(true);
      } catch (err) {
        console.log("err: ", err);
        resolve(false);
      }
    });
  };
};
