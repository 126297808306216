import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import routes from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { setDarkThemeForApp, connectPlayer, playerError, setMVIX } from "./actions";
import { getDeviceId } from "./util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrinBeamSweat } from "@fortawesome/pro-solid-svg-icons";
import PlayerAlreadyConnectedModal from "./components/player-already-connected-modal";
import PageLoader from "./components/page-loader";
import { get } from "lodash";
import "antd/dist/antd.css";
import "./App.less";

const App = () => {
  const dispatch = useDispatch();
  const showPlayer = useSelector((state) => state.player.showPlayer);
  const ambii_player_jwt = localStorage.getItem("ambii_player_jwt");
  let mvix = get(window, "location.pathname", "").includes("mvix");

  // If user coming from mvix, set in redux
  if (mvix) dispatch(setMVIX(true));

  useEffect(() => {
    const darkTheme = JSON.parse(localStorage.getItem("darkTheme"));
    if (darkTheme === null) dispatch(setDarkThemeForApp(true));
    else dispatch(setDarkThemeForApp(darkTheme));
    // eslint-disable-next-line
  }, []);

  //functions
  const UrlConnect = () => {
    const routeMatch = useRouteMatch();
    const history = useHistory();
    const [playerInfo] = useState({
      deviceName: mvix ? "Web Player" : "Ambii Web Player",
      deviceId: getDeviceId(),
      deviceBrand: "Web",
      override: false,
      storeCode: routeMatch?.params?.storeCode.toUpperCase(),
      utc_offset: new Date().getTimezoneOffset(),
    });

    useEffect(() => {
      if (routeMatch.params.storeCode.length === 6) {
        //clean localstorage before connecting w/ url
        localStorage.removeItem("storeCode");
        localStorage.removeItem("ambii_player_jwt");
        dispatch(connectPlayer(playerInfo))
          .then((res) => {
            if (res === "DEVICE_ALREADY_PAIRED") {
              dispatch(
                playerError({
                  error: true,
                  title: "Another Device Has Connected!",
                  message: "Please press the 'play' button if you wish to reconnect with this device.",
                  icon: <FontAwesomeIcon icon={faGrinBeamSweat} className={"connect-icon"} />,
                  playerInfo,
                })
              );
            }
          })
          .catch(() => {
            history.push("/connect");
          });
      }
      // eslint-disable-next-line
    }, []);

    return routeMatch.params.storeCode.length === 6 ? (
      <>
        <PageLoader
          text={
            <div className={"connecting-title-wrapper"}>
              <div className="title">Connecting to player</div>
              <div className="store-code">{routeMatch?.params?.storeCode.toUpperCase()}</div>
            </div>
          }
        />
        <PlayerAlreadyConnectedModal />
      </>
    ) : (
      <Redirect to="/connect" />
    );
  };

  return (
    <>
      {/* React Router */}
      <Router>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={() => {
                if (route.private && showPlayer && ambii_player_jwt) return route.component();
                else if (!route.private) return route.component();
                else return <UrlConnect />;
              }}
            />
          ))}
        </Switch>
      </Router>
    </>
  );
};

export default App;
