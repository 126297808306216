// Location
export const SET_LOCATION = "SET_LOCATION";
export const SET_STATION = "SET_STATION";
export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_CURRENT_TRACK = "SET_CURRENT_TRACK";
export const SET_EMPTY_LOCATION = "SET_EMPTY_LOCATION";

// Player
export const SET_TRACK_SCHEDULE = "SET_TRACK_SCHEDULE";
export const SET_LAST_TRACK = "SET_LAST_TRACK";
export const SET_TRACK_MAP = "SET_TRACK_MAP";
export const SET_TRACK_NUMBER = "SET_TRACK_NUMBER";
export const SET_IS_PLAYING = "SET_IS_PLAYING";
export const SET_SHOW_PLAYER = "SET_SHOW_PLAYER";
export const PLAYER_ERROR = "PLAYER_ERROR";
export const SET_SKIP_DISABLED = "SET_SKIP_DISABLED";
export const SET_PLAYER_VOLUME = "SET_PLAYER_VOLUME";
export const ADD_TO_TRACK_MAP = "ADD_TO_TRACK_MAP";
export const SET_SONG_COMPLETION = "SET_SONG_COMPLETION";
export const SET_IS_ASSIGNING_STATION = "SET_IS_ASSIGNING_STATION";
export const SET_SHOW_SIDE_MENU = "SET_SHOW_SIDE_MENU";

// Volume controls
export const SET_FADE_ARRAY = "SET_FADE_ARRAY";
export const SET_FADE_ARRAY_INDEXES = "SET_FADE_ARRAY_INDEXES";

// Stations
export const SET_STATIONS = "SET_STATIONS";
export const SET_STATION_TO_BE_ASSIGNED = "SET_STATION_TO_BE_ASSIGNED";

// Announcements
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_MESSAGE_HASH = "SET_MESSAGE_HASH";
export const SET_INTERVAL_MESSAGES_MAP = "SET_INTERVAL_MESSAGES_MAP";
export const SET_SCHEDULED_MESSAGES_MAP = "SET_SCHEDULED_MESSAGES_MAP";
export const SET_MESSAGE_QUEUE = "SET_MESSAGE_QUE";

// Favorites
export const SET_FAVORITES = "SET_FAVORITES";

// Settings
export const SET_MVIX = "SET_MVIX";
