import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { images } from "../../constants";
import { Button, Input, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { connectPlayer, playerError } from "../../actions";
import { getDeviceId } from "../../util";
import { faKey, faStore } from "@fortawesome/pro-solid-svg-icons";
import PageLoader from "../../components/page-loader";
import PlayerAlreadyConnectedModal from "../../components/player-already-connected-modal";
import UncontrolledLottie from "../../components/lottie/uncontrolled-lottie";
import WaterRipple from "../../common/lotties/water-ripple.json";
import { isAndroid } from "react-device-detect";
import "./index.less";

const Connect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let localStoreCode = localStorage.getItem("storeCode");
  let ambii_player_jwt = localStorage.getItem("ambii_player_jwt");
  let addressLineOne = localStorage.getItem("addressLineOne");

  // Redux-hooks
  const { darkTheme, mvix } = useSelector((state) => ({
    darkTheme: state.darkTheme.darkTheme,
    mvix: state.settings.mvix,
  }));

  useEffect(() => {
    const connectWrapper = document.querySelector("div.connect-page-wrapper");
    connectWrapper.addEventListener("paste", pasteHandler);
    return () => {
      connectWrapper.removeEventListener("paste", pasteHandler);
    };
  }, []); // Did mount listener

  const pasteHandler = (event) => {
    let pastedCode = (event.clipboardData || window.clipboardData).getData("text");
    pastedCode = pastedCode.toUpperCase();
    if (pastedCode.length === 6) {
      setStoreCode({
        [`input-1`]: pastedCode[0],
        [`input-2`]: pastedCode[1],
        [`input-3`]: pastedCode[2],
        [`input-4`]: pastedCode[3],
        [`input-5`]: pastedCode[4],
        [`input-6`]: pastedCode[5],
      });
      setIsPasted(true);
    } else {
      notification.error({ message: "Wrong Store Code", description: "Please check you store code.", placement: "bottomLeft" });
    }
  };

  useEffect(() => {
    changeBackgroundWaveColor();
    // eslint-disable-next-line
  }, [darkTheme]); // Theme toggle listener

  // Changes the background wave color
  const changeBackgroundWaveColor = () => {
    let interval = setInterval(() => {
      let backgroundWaveSVGColor = document.getElementById("connect-background-wave");

      if (backgroundWaveSVGColor) {
        backgroundWaveSVGColor.children[0].children[0].children[1].children[0].children[0].children[0].setAttribute("fill", darkTheme ? "#fff" : "#000");
        clearInterval(interval);
      }
    }, 100);
  };

  //state(s)
  const [playerInfo] = useState({ deviceName: "web", deviceId: getDeviceId(), deviceBrand: "web", override: false, utc_offset: new Date().getTimezoneOffset() });
  let [storeCode, setStoreCode] = useState(ambii_player_jwt ? localStoreCode : []);
  const [quickConnectLoader, setQuickConnectLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageLoader, setPageLoader] = useState(false);
  const [androidInput, setAndroidInput] = useState("");
  const [isPasted, setIsPasted] = useState(false);

  const connectAudioPlayer = async () => {
    setLoading(true);
    let dataToSend;
    if (typeof storeCode === "object") dataToSend = isAndroid ? androidInput : Object.values(storeCode).join("");
    else dataToSend = storeCode;
    dispatch(connectPlayer({ ...playerInfo, storeCode: dataToSend }))
      .then((response) => {
        if (response)
          if (response && response !== "DEVICE_ALREADY_PAIRED") history.push(`/${response?.location?.storeCode}`);
          else if (response) {
            dispatch(playerError({ error: true, title: "Another Device Has Connected!", message: "Please press the 'play' button if you wish to reconnect with this device.", playerInfo: { ...playerInfo, storeCode: Object.values(storeCode).join("") } }));
            setLoading(false);
            setIsPasted(false);
          } else {
            setLoading(false);
            setIsPasted(false);
            notification.error({ message: "Failed to connect player.", description: "Please check you store code.", placement: "bottomLeft" });
          }
      })
      .catch(() => {
        setLoading(false);
        setIsPasted(false);
      });
  };

  useEffect(() => {
    if (playerInfo.override) connectAudioPlayer();
    else if (ambii_player_jwt) {
      setPageLoader(true);
      connectAudioPlayer();
    } else setLoading(false);

    if (!ambii_player_jwt && !playerInfo.override) {
      setPageLoader(false);
      setLoading(false);
    }

    if (storeCode.length === 6 && localStoreCode && !ambii_player_jwt && loading) connectAudioPlayer();
    if (typeof storeCode === "object" && isPasted) {
      connectAudioPlayer();
    }
    // eslint-disable-next-line
  }, [playerInfo, storeCode, isPasted]);

  //functions
  const handleTextChange = (e) => {
    const { value, name } = e.target;
    // eslint-disable-next-line
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex);

    if (value && fieldIntIndex < 7) {
      const nextfield = document.querySelector(`input[name=input-${fieldIntIndex + 1}]`);

      setStoreCode({ ...storeCode, [name]: value.toUpperCase() });

      if (nextfield !== null) {
        nextfield.focus();
      }
    }
  };

  const onKeyDownHandler = (e) => {
    const key = e.keyCode || e.charCode;
    // console.log({ e, key, which: e.which });
    if (key === 8) {
      const previousFieldName = parseInt(e.target.name.slice(6)) - 1;
      if (previousFieldName !== 0) {
        const prevField = document.querySelector(`input[name=input-${previousFieldName}]`);
        setStoreCode({ ...storeCode, [e.target.name]: "" });
        prevField.focus();
      } else {
        setStoreCode({ ...storeCode, [e.target.name]: "" });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    connectAudioPlayer();
  };

  const isDisabled = () => {
    const isLongEnough = isAndroid ? androidInput.length === 6 : Object.values(storeCode).join("").length === 6;
    if (!isLongEnough) return true;
  };

  return (
    <>
      {pageLoader ? (
        <PageLoader />
      ) : (
        <div className="connect-page-wrapper">
          {/* Background Layer  */}
          <div className={"background-wrapper"}>
            <div id={"connect-background-wave"}>
              <UncontrolledLottie animationData={WaterRipple} width={"100vw"} height={"100vh"} speed={0.6} />
            </div>
          </div>

          {/* Foreground Layer */}
          <div className="outer-wrapper">
            <div className={"inner-wrapper"}>
              <div className="connect-header">
                {!mvix ? (
                  <>
                    <img src={images.appLogo.default} alt="logo" className="ambii-logo" />
                    <div className="connect-header-content">
                      <h3>Ambii</h3>
                      Music for business
                    </div>
                  </>
                ) : (
                  <div className="mvix-logo-wrapper">
                    <img src={"https://public-ambii-images.s3.us-west-2.amazonaws.com/mvix-logo.png"} alt="logo" className="mvix-logo" />
                    <div className="connect-header-content">
                      <h3>MVIX Radio</h3>
                      Music for business
                    </div>
                  </div>
                )}
              </div>

              <div className="connect-wrapper">
                <div className="main-column">
                  <div className="store-code-title">Enter Store Code</div>
                  <div className="store-code-form-wrapper">
                    {/* Because android's input always sends 229  */}
                    {isAndroid ? (
                      <form className={"store-code-form"} onSubmit={handleSubmit} id="android-store-code-form">
                        <Input maxLength="6" className="store-code-box" value={androidInput} onChange={(e) => setAndroidInput(e.target.value.toUpperCase())} />
                      </form>
                    ) : (
                      <form className={"store-code-form"} onSubmit={handleSubmit} id="store-code-form">
                        <Input placeholder={"A"} name={"input-1"} autoFocus className="store-code-box" maxLength="1" onKeyDown={onKeyDownHandler} onChange={handleTextChange} value={storeCode["input-1"] || ""} style={{ borderColor: storeCode["input-1"] ? "#d04282" : "#d0428283" }} />
                        <Input placeholder={"B"} name={"input-2"} className="store-code-box" maxLength="1" onKeyDown={onKeyDownHandler} onChange={handleTextChange} value={storeCode["input-2"] || ""} style={{ borderColor: storeCode["input-2"] ? "#d04282" : "#d0428283" }} />
                        <Input placeholder={"C"} name={"input-3"} className="store-code-box" maxLength="1" onKeyDown={onKeyDownHandler} onChange={handleTextChange} value={storeCode["input-3"] || ""} style={{ borderColor: storeCode["input-3"] ? "#d04282" : "#d0428283" }} />
                        <Input placeholder={"1"} name={"input-4"} className="store-code-box" onKeyDown={onKeyDownHandler} onChange={handleTextChange} maxLength="1" value={storeCode["input-4"] || ""} style={{ borderColor: storeCode["input-4"] ? "#d04282" : "#d0428283" }} />
                        <Input placeholder={"2"} name={"input-5"} className="store-code-box" onKeyDown={onKeyDownHandler} onChange={handleTextChange} maxLength="1" value={storeCode["input-5"] || ""} style={{ borderColor: storeCode["input-5"] ? "#d04282" : "#d0428283" }} />
                        <Input placeholder={"3"} name={"input-6"} className="store-code-box" onKeyDown={onKeyDownHandler} onChange={handleTextChange} maxLength="1" value={storeCode["input-6"] || ""} style={{ borderColor: storeCode["input-6"] ? "#d04282" : "#d0428283" }} />
                      </form>
                    )}
                  </div>
                  <div className="store-code-button-wrapper">
                    <Button disabled={isDisabled()} form={isAndroid ? "android-store-code-form" : "store-code-form"} htmlType="submit" className="intro-button" loading={loading} type="primary">
                      <div className={"intro-button-icon"}>
                        <FontAwesomeIcon icon={faKey} />
                      </div>
                      <span className={"intro-button-text"}>Connect</span>
                      <div className={"intro-button-icon"} />
                    </Button>
                    {addressLineOne && (
                      <Button
                        onClick={async () => {
                          // setStoreCode(localStoreCode);
                          setQuickConnectLoader(true);
                          const dataToSend = {
                            ...playerInfo,
                            storeCode: localStoreCode,
                          };

                          const response = await dispatch(connectPlayer({ ...dataToSend }));

                          if (response !== "DEVICE_ALREADY_PAIRED" && response !== false) history.push(`/${response.location.storeCode}`);
                          else if (response) {
                            dispatch(playerError({ error: true, title: "Another Device Has Connected!", message: "Please press the 'play' button if you wish to reconnect with this device.", playerInfo: dataToSend }));
                            setQuickConnectLoader(false);
                          } else setQuickConnectLoader(false);
                        }}
                        className="intro-button alt-button"
                        loading={quickConnectLoader}
                        type="primary"
                      >
                        <div className={"intro-button-icon"}>
                          <FontAwesomeIcon icon={faStore} />
                        </div>
                        <span className={"intro-button-text"}>
                          Quick Reconnect <br />
                          <span style={{ fontWeight: "normal" }}>{addressLineOne}</span>
                        </span>
                        <div className={"intro-button-icon"} />
                      </Button>
                    )}
                  </div>
                  <div className="sign-up" onClick={() => window.open("https://business.ambii.io", "_blank")}>
                    <span className={"sign-up-text"}>Don't have a code? Sign Up!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <PlayerAlreadyConnectedModal />
    </>
  );
};

export default Connect;
