import { Redirect } from "react-router";
import Connect from "../containers/connect";
import Player from "../containers/player";

const routes = [
  {
    path: "/",
    exact: true,
    isChild: false,
    private: false,
    component: () => <Redirect to="/connect" from="/" />,
  },
  {
    path: "/connect",
    exact: true,
    isChild: false,
    private: false,
    component: () => <Connect />,
  },
  {
    path: "/:storeCode",
    exact: true,
    isChild: false,
    private: true,
    component: () => <Player />,
  },
  {
    path: "/mvix",
    exact: true,
    isChild: false,
    private: false,
    component: () => <Connect />,
  },
  {
    path: "/mvix/:storeCode",
    exact: true,
    isChild: false,
    private: true,
    component: () => <Redirect to={"/:storeCode"} />,
  },
];

export default routes;
