import { ADD_TO_TRACK_MAP, SET_SKIP_DISABLED, PLAYER_ERROR, SET_TRACK_MAP, SET_TRACK_NUMBER, SET_IS_PLAYING, SET_SHOW_PLAYER, SET_PLAYER_VOLUME, SET_MESSAGE_QUEUE, SET_FADE_ARRAY, SET_FADE_ARRAY_INDEXES, SET_SONG_COMPLETION, SET_IS_ASSIGNING_STATION, SET_SHOW_SIDE_MENU, SET_STATION_TO_BE_ASSIGNED } from "./../constants";
import initialState from "../redux-store/initialState";

const player_reducer = (state = initialState.player, action) => {
  switch (action.type) {
    case SET_TRACK_MAP:
      return Object.assign({}, state, { trackMap: action.data });
    case ADD_TO_TRACK_MAP: {
      if (!action?.data?.key || !action?.data?.value) return;
      let newTrackMap = { ...state.trackMap };
      newTrackMap[action?.data?.key] = action?.data?.value;
      return Object.assign({}, state, { trackMap: newTrackMap });
    }
    case SET_TRACK_NUMBER:
      return Object.assign({}, state, { trackNumber: action.data });
    case SET_IS_PLAYING:
      return Object.assign({}, state, { isPlaying: action.data });
    case SET_SKIP_DISABLED:
      return Object.assign({}, state, { skipDisabled: action.data });
    case SET_SHOW_PLAYER:
      return Object.assign({}, state, { showPlayer: action.data });
    case PLAYER_ERROR:
      return Object.assign({}, state, { playerError: action.data });
    case SET_PLAYER_VOLUME:
      return Object.assign({}, state, { playerVolume: action.data });
    case SET_MESSAGE_QUEUE:
      return Object.assign({}, state, { messageQueue: action.data });
    case SET_FADE_ARRAY:
      return Object.assign({}, state, { fadeArray: action.data });
    case SET_FADE_ARRAY_INDEXES:
      return Object.assign({}, state, { fadeArrayIndexes: action.data });
    case SET_SONG_COMPLETION:
      return Object.assign({}, state, { songCompletion: action.data });
    case SET_IS_ASSIGNING_STATION:
      return Object.assign({}, state, { isAssigningStation: action.data });
    case SET_SHOW_SIDE_MENU:
      return Object.assign({}, state, { showSideMenu: action.data });
    case SET_STATION_TO_BE_ASSIGNED:
      return { ...state, stationToBeAssigned: action.data };
    default:
      return state;
  }
};

export default player_reducer;
