import { combineReducers } from "redux";
import location from "./location";
import messaging from "./messaging";
import player from "./player";
import stations from "./stations";
import darkTheme from "./darkTheme";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import favorites from "./favorites";
import settings from "./settings";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["stations"],
};

const rootReducer = combineReducers({
  favorites,
  location,
  messaging,
  player,
  stations,
  darkTheme,
  settings,
});

export default persistReducer(persistConfig, rootReducer);
