//prettier
import { HTTP } from "../configs";
import { logError, getAnnouncements, setSchedule, showPlayer, setLastTrack, setDisableSkip, setTrackNumber, setTrackMap, setLocation, setTrackSchedule, setStation, setCurrentTrack, setFadeArray, setFadeArrayIndexes, setPlayerVolume, setMessages, setMessageHash, setIntervalMessagesMap, setScheduledMessagesMap, setSongCompletion, setFavorites } from "./";
import { get } from "lodash";
import { notification } from "antd";
import { objArrayToMap, getDeviceId, getTrackNumberByTrack, getQuadraticRoots } from "../util";
import { responses } from "../constants";

let fadeVolume = 1; // Fade volume level

export function disconnectPlayer() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", "/v3/api/player/disconnect", { deviceId: getDeviceId() })
        .then((response) => {
          if (get(response, "success", false)) {
            localStorage.removeItem("ambii_player_jwt");

            //redux cleanup
            dispatch(setMessages({}));
            dispatch(setMessageHash(""));
            dispatch(setIntervalMessagesMap({}));
            dispatch(setScheduledMessagesMap({}));
            dispatch(setSongCompletion(0));
            dispatch(setTrackMap([]));
            dispatch(setTrackNumber(0));
          } else notification.error({ message: responses.status.error, description: responses.description.failedToDisconnect, placement: "bottomLeft" });
          resolve(true);
        })
        .catch((err) => {
          dispatch(logError({ name: "disconnectPlayer", err }));
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          reject(err);
        });
    });
  };
}

export function connectPlayer(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP("post", "/v3/api/player/connect", data)
        .then((response) => {
          if (get(response, "success", false)) {
            let data = get(response, "data", {});
            if (data === "DEVICE_ALREADY_PAIRED") return resolve("DEVICE_ALREADY_PAIRED");
            // Location not connected to
            localStorage.setItem("ambii_player_jwt", get(data, "ambii_player_jwt", null)); // Save player jwt to local storage

            let location = get(data, "location", {});
            let trackSchedule = get(location, "trackSchedule", {});
            let trackMap = objArrayToMap(get(trackSchedule, "queue", []), "isrc");
            let currentTrack = get(location, "currentTrack", null); // Get the currentTrackNumber from server
            let currentTrackNumber = get(location, "currentTrackNumber", 0); // Get the currentTrackNumber from server
            let currentTrackByNumber = get(trackSchedule, "queue[" + currentTrackNumber + "]", currentTrack); // Get the currentTrack by number

            // Get Location's Messages
            localStorage.setItem("storeCode", location.storeCode);
            localStorage.setItem("addressLineOne", location.addressLineOne);

            // Dispatch parent data first
            dispatch(fetchFavourites());
            dispatch(setLocation(location)); // Save location updates
            dispatch(getAnnouncements());
            dispatch(setTrackMap(trackMap)); // Save track map
            dispatch(setTrackNumber(currentTrackNumber)); // Save track map
            dispatch(setCurrentTrack(currentTrackByNumber));

            // Dispatch specific data into parent
            dispatch(setTrackSchedule(trackSchedule)); // Set Track Schedule
            dispatch(showPlayer(true));

            resolve(data);
            notification.success({ message: responses.status.success, description: responses.description.connected, placement: "bottomLeft" });
          } else notification.error({ message: responses.status.error, description: responses.description.failedToConnect, placement: "bottomLeft" });
          resolve(true);
        })
        .catch((err) => {
          const { message } = get(err, "response.data", {});
          dispatch(logError({ name: "connectPlayer", err }));
          notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
          reject(err);
        });
    });
  };
}

export function finishedTrack(incomingData) {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      try {
        // Return if required data missing
        if (!incomingData) return resolve(false);

        // Update Location's Messages
        dispatch(getAnnouncements());

        HTTP("post", "/v3/api/player/finishedTrack", incomingData)
          .then(async (response) => {
            console.log("finishedTrack res: ", response);

            if (get(response, "success", false)) {
              let data = get(response, "data", {});

              const prevPlayerVol = getState().player.playerVolume;
              const schedule = get(data, "schedule", null);
              const newStation = get(data, "newStation", false);
              const newTrackSchedule = get(data, "newTrackSchedule", false);
              let currentTrack = get(getState(), "location.currentTrack", get(data, "track", null));
              let trackNumber = get(getState(), "player.trackNumber", 0);

              // Check for DEVICE_ALREADY_PAIRED warning
              if (data === "DEVICE_ALREADY_PAIRED") return resolve(data);

              // If the station has changed in the background
              if (newStation) {
                await dispatch(fadeOutVolume()); // Fade Away the currently playing song
                let station = get(data, "station", {});
                dispatch(setStation(station)); // Set location updates
                dispatch(setPlayerVolume(prevPlayerVol));

                // If schedule has changed, update the display data
                if (schedule) dispatch(setSchedule(schedule));
                else dispatch(setSchedule(null));
              }

              // If the track schedule has changed
              if (newTrackSchedule) {
                let trackSchedule = get(data, "trackSchedule", {});
                let trackMap = objArrayToMap(get(trackSchedule, "queue", []), "isrc");

                // Dispatch parent data first
                dispatch(setTrackMap(trackMap)); // Set track map

                // May be needed, as change in track schedule may shift things around
                let newTrackNumber = newStation ? 0 : getTrackNumberByTrack(currentTrack, get(trackSchedule, "queue", []));
                if (trackNumber !== newTrackNumber) {
                  if (newTrackNumber || newTrackNumber === 0) dispatch(setTrackNumber(newTrackNumber));
                  else {
                    // Current track was not in the new queue
                    let lastTrack = get(getState(), "location.lastTrack", ""); // Get 'lastTrack'
                    newTrackNumber = getTrackNumberByTrack(lastTrack, get(trackSchedule, "queue", [])); // Get 'lastTrack' position in new queue

                    // Set track number
                    dispatch(setTrackNumber(newTrackNumber ? newTrackNumber : trackNumber));
                  }
                }

                // Dispatch specific data into parent
                dispatch(setTrackSchedule(trackSchedule)); // Set Filtered Track Schedule
                if (newStation) dispatch(setCurrentTrack(trackSchedule.queue[0]));
              }
              return resolve(true);
            }

            resolve(false);
          })
          .catch(async (err) => {
            const { message, type } = get(err, "response.data", {});
            dispatch(logError({ name: "finishedTrack", err }));

            if (type === "TOKEN_INVALID" || type === "TOKEN_EXPIRED" || type === "LOCATION_DOES_NOT_EXIST" || type === "INACTIVE_LOCATION") {
              localStorage.removeItem("ambii_player_jwt");
              localStorage.removeItem("addressLineOne");
              localStorage.removeItem("storeCode");
              notification.error({ message: responses.status.error, description: message, placement: "bottomLeft" });
              await dispatch(disconnectPlayer());
              window.location.reload();
            }
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  };
}

export function skipSong({ songCompletion }) {
  return function (dispatch, getState) {
    return new Promise(async (resolve) => {
      try {
        if (!songCompletion) songCompletion = "0";

        let location = get(getState(), "location", {});
        let player = get(getState(), "player", {});

        dispatch(setDisableSkip(true));
        let trackSchedule = get(location, "trackSchedule", "");
        let trackScheduleLength = get(trackSchedule, "queue", []).length - 1;
        let queue = get(trackSchedule, "queue", []);
        let lastTrack = get(location, "currentTrack", {});
        let trackNumber = get(player, "trackNumber", 0);
        let nextTrack = null;

        // Get the next track / track number
        if (trackNumber + 1 <= trackScheduleLength) {
          nextTrack = queue[trackNumber + 1] || "";
          trackNumber += 1;
        } else {
          nextTrack = get(location, "trackSchedule.queue[" + 0 + "]", "");
          trackNumber = 0;
        }

        dispatch(setLastTrack(lastTrack)); // Reset last track
        dispatch(setCurrentTrack(nextTrack));
        dispatch(setTrackNumber(trackNumber));

        resolve(true);
      } catch (err) {
        dispatch(setDisableSkip(false));
        console.log("caught callPlayNext err: ", err);
        resolve(false);
      }
    });
  };
}

// Checks to see if we need to disable skip on the current song
export function checkIfAdvertisement(currentTrack) {
  return function (dispatch) {
    let isAdvertisement = get(currentTrack, "isrc", "").substring(0, 7) === "ADVERT_";
    let isInStoreMessage = get(currentTrack, "isrc", "").substring(0, 4) === "ISM_";

    if (isAdvertisement || isInStoreMessage) dispatch(setDisableSkip(true));
    else {
      // dispatch(setDisableSkip(false));
      let skipTimer = setTimeout(() => {
        clearTimeout(skipTimer);
        dispatch(setDisableSkip(false));
      }, 1000); // How long to disable skip for
    }
  };
}

// Calculate all values in our fade,
export const calculateFadeArray = () => {
  return (dispatch, getState) => {
    try {
      const { fadeStretch, fadeDuration } = getState().player;
      let stopAtVolume = 0.05; // Volume to stop calculating our fade
      let baseValue = 1; // Base value of our x (root)
      let rateOfChange = 0.1; // The rate of change value to our x (root)
      let root = getQuadraticRoots(baseValue, 0, -fadeStretch); // Root is our x value
      let fadeArray = [];

      // Calculate all values in our fade, and put them into an array (if less than current volume)
      while (Math.pow(root, 2) / fadeStretch > stopAtVolume) {
        fadeArray.push(Math.pow(root, 2) / fadeStretch); // Push value into our array
        root = getQuadraticRoots(parseFloat((baseValue += rateOfChange).toFixed(1)), 0, -fadeStretch); // Recalculate x (root)
      }

      let fadeCounter = 0; // A counter for how many times we've looped
      let interval = (fadeArray.length / fadeDuration) * (fadeDuration * 0.01); // The interval at which we pick elements from our calculated array
      let index = 0; // Index we should access within our calculated array
      let fadeArrayIndexes = [];

      // Calculate the indexes we'll be using to access fadeArray
      while (index <= fadeArray.length) {
        index += Math.floor(fadeCounter * interval);
        fadeArrayIndexes.push(index);
        fadeCounter++;
      }

      // If index overflows fadeArray length, pop the last element
      if (index >= fadeArray.length) fadeArrayIndexes.pop();

      // Save to state
      dispatch(setFadeArray(fadeArray));
      dispatch(setFadeArrayIndexes(fadeArrayIndexes));
    } catch (err) {
      console.log("caught fadeOutVolume err: ", err);
    }
  };
};

export const fadeOutVolume = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve) => {
      try {
        const { fadeArray, fadeArrayIndexes } = getState().player;

        let volume = getState().player.playerVolume; // Grab player volume
        let previousVolume = getState().player.playerVolume;

        let fadeCounter = 0;
        let index = 0;

        // Execute fade effect
        let fadeOut = setInterval(async () => {
          index = fadeArrayIndexes[fadeCounter];
          volume = fadeArray[index];
          fadeCounter++;

          if (fadeCounter >= fadeArrayIndexes.length) {
            clearInterval(fadeOut);
            fadeVolume = 0;
            dispatch(setPlayerVolume(1 * fadeVolume));

            // return volumeByTrackType * fadeVolume;
            return resolve(true);
          } else {
            fadeVolume = volume; // Lower volume
            dispatch(setPlayerVolume(previousVolume * fadeVolume));
          }
        }, 50);
      } catch (err) {
        console.log("caught fadeOutVolume err: ", err);
        resolve(false);
      }
    });
  };
};

// // Fades volume in (from 0 -> lastVolume)
export const fadeInVolume = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve) => {
      try {
        const { fadeArray, playerVolume } = getState().player;
        const { isMusic } = getState().location.currentTrack;
        if (isMusic) dispatch(setPlayerVolume(playerVolume * 0.6));
        let previousPlayerVolume = getState().player.playerVolume;
        let volume = 0;
        let fadeCounter = 0;
        let index = 0;
        let fadeArrayIndexes = [...getState().player.fadeArrayIndexes];
        fadeArrayIndexes = fadeArrayIndexes.reverse();

        // Execute fade in effect
        let fadeIn = setInterval(async () => {
          index = fadeArrayIndexes[fadeCounter];
          volume = fadeArray[index];
          fadeCounter++;

          if (fadeCounter >= fadeArrayIndexes.length) {
            clearInterval(fadeIn);
            fadeVolume = 1;

            dispatch(setPlayerVolume(previousPlayerVolume));
            return resolve(true);
          } else {
            fadeVolume = volume; // Raise volume
            dispatch(setPlayerVolume(fadeVolume * previousPlayerVolume));
          }
        }, 50);
      } catch (err) {
        console.log("caught fadeInVolume err: ", err);
        resolve(false);
      }
    });
  };
};

export const fetchFavourites = () => async (dispatch) => {
  try {
    let response = await HTTP("get", "/v3/api/player/favorite");
    if (get(response, "success", false)) dispatch(setFavorites(get(response, "data", {})));
  } catch (err) {
    console.log("favorites err:", err);
  }
};

export const downVote = (reasonNo) => async (dispatch, getState) => {
  try {
    const downVoteEnums = ["TRACK_DISLIKED", "TRACK_OVERPLAYED", "TRACK_BAD_FIT", "EXPLICIT_ALBUM_ART"];
    const stationId = getState().location.station._id;
    const trackId = getState().location.currentTrack._id;
    let response = await HTTP("post", "/v3/api/player/downvote", { reason: downVoteEnums[reasonNo], stationId, trackId });
    if (get(response, "success", false)) notification.success({ message: get(response, "message", responses.status.success), description: "Thanks for letting us know!", placement: "bottomLeft" });
  } catch (err) {
    console.log(err);
    notification.error({ message: "Failure", description: "Server error", placement: "bottomLeft" });
  }
};
