// import { SET_DARK_THEME } from "../constants";
import { SET_FAVORITES } from "../constants";
import initialState from "../redux-store/initialState";

const favorites_reducer = (state = initialState.favorites, action) => {
  switch (action.type) {
    case SET_FAVORITES:
      return action.data;
    default:
      return state;
  }
};

export default favorites_reducer;
