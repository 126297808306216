import { SET_MVIX } from "./../constants";
import initialState from "../redux-store/initialState";

const settings_reducer = (state = initialState.settings, action) => {
  switch (action.type) {
    case SET_MVIX:
      return Object.assign({}, state, { mvix: action.data });
    default:
      return state;
  }
};

export default settings_reducer;
