import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "antd";
import { setIsPlaying, showPlayer, disconnectPlayer } from "../../actions";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { colors } from "../../styles/colors";

const DisconnectModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [disconnecting, setDisconnecting] = useState(false);

  return (
    <Modal
      title={
        <>
          <ExclamationCircleOutlined style={{ color: colors.warningColor, marginRight: "10px" }} /> Disconnect
        </>
      }
      visible={visible}
      okText={"Yes, Disconnect"}
      cancelText={"Cancel"}
      className={"custom-modal-wrapper"}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="ok"
          onClick={async () => {
            setDisconnecting(true);
            dispatch(setIsPlaying(false));
            await dispatch(disconnectPlayer());
            history.push("/connect");
            dispatch(showPlayer(false));
            setDisconnecting(false);
          }}
          loading={disconnecting}
        >
          Yes, Disconnect
        </Button>,
        <Button key="cancel" type="primary" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
      ]}
    >
      Are you sure you would like to disconnect this player?
    </Modal>
  );
};

export default DisconnectModal;
