export const colors = {
  white: "#ffffff",
  whiteAlt: "#f5f5f5",
  black: "#000000",
  blackAlt: "#2a2a2a",
  dark: "#2d3436",
  gray: "#ccc",
  grayAlt: "#717171",
  lightGray: "#e2e2e2",
  darkGray: "#636e72",
  lightPink: "#ce4890",
  purple: "#c178ff",
  deepPurple: "#1c1521",
  pink: "#d04283",
  linkColor: "#ce4890",
  successColor: "#52c41a",
  warningColor: "#faad14",
  errorColor: "#f5222d",
  headingColor: "rgba(0, 0, 0, 0.85)",
  textColor: "rgba(0, 0, 0, 0.65)",
  textColorSecondary: "rgba(0, 0, 0, 0.45)",
  disabledColor: "rgba(0, 0, 0, 0.25)",
  bodyBackground: "#f5f5f5",
  componentBackground: "#ffffff",
  opaqueWhite: "rgba(255, 255, 255, 0.25)",
  opaquteBlack: "rgba(0, 0, 0, 0.25)",
};
