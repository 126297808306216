import { useSelector } from "react-redux";
import WhiteSoundWave from "../../common/lotties/white-sound-wave.json";
import BlackSoundWave from "../../common/lotties/black-sound-wave.json";
import DarkStarBackground from "../../common/lotties/dark-star-background.json";
import LightStarBackground from "../../common/lotties/light-star-background.json";
import UncontrolledLottie from "../lottie/uncontrolled-lottie";
import "./index.less";

const PageLoader = ({ text }) => {
  const { darkTheme } = useSelector((state) => ({ darkTheme: state.darkTheme.darkTheme }));

  return (
    <div className={"page-load-wrapper"}>
      <div id={"loader-background-wave"}>
        <UncontrolledLottie animationData={darkTheme ? LightStarBackground : DarkStarBackground} width={"100vw"} height={"100vh"} speed={0.3} />
      </div>
      <div id={"loader-sound-wave"}>
        <UncontrolledLottie animationData={darkTheme ? WhiteSoundWave : BlackSoundWave} width={"100px"} height={"100px"} speed={0.6} style={{ margin: "0px" }} />
      </div>
      <div className={"text"}>{text ? text : "Loading"} </div>
      <div className={"spacer"} />
    </div>
  );
};

export default PageLoader;
