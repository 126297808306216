import FastAverageColor from "fast-average-color";
import Vibrant from "node-vibrant";
import colorContrast from "color-contrast";
import { useState, useEffect } from "react";

const useProgressiveImage = ({ buffer }) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [componentBackground, setComponentBackground] = useState("#ffffff");
  const [headingColor, setHeadingColor] = useState("#ffffff");
  const [musicBarScrubber, setMusicBarScrubber] = useState("#000000");
  const [bodyColor, setBodyColor] = useState("#ffffff");

  const checkContrast = (colorOne, ColorTwo) => {
    let constrast = colorContrast(colorOne, ColorTwo) > 0.8;
    return constrast;
  };

  useEffect(() => {
    setSourceLoaded(buffer);
    if (buffer) {
      let fac = new FastAverageColor();
      fac.getColorAsync(buffer).then((res) => {
        let v = new Vibrant(buffer);

        let backgroundColorLight = res.value[0] * 0.299 + res.value[1] * 0.587 + res.value[2] * 0.114 < 186;

        v.getPalette().then((palette) => {
          if (backgroundColorLight) {
            setComponentBackground("#fffffff0");
            setBackgroundColor("#1c1521");
          } else {
            setComponentBackground("#1c1521");
            setBackgroundColor("#fffffff0");
          }

          if (checkContrast(backgroundColor, palette.Vibrant.hex)) {
            setHeadingColor(palette.Vibrant.hex);
            setBodyColor(palette.Vibrant.bodyTextColor);
            setMusicBarScrubber(palette.DarkMuted.hex);
          } else {
            if (backgroundColorLight) {
              if (checkContrast(backgroundColor, palette.LightVibrant.hex)) {
                setHeadingColor(palette.LightVibrant.hex);
                setBodyColor(palette.LightVibrant.bodyTextColor);
                setMusicBarScrubber(palette.DarkMuted.hex);
              } else {
                setHeadingColor(palette.DarkMuted.hex);
                setBodyColor(palette.DarkMuted.bodyTextColor);

                setMusicBarScrubber(palette.LightMuted.hex);
              }
            } else {
              setHeadingColor(palette.DarkVibrant.hex);
              setBodyColor(palette.LightVibrant.bodyTextColor);
              setMusicBarScrubber(palette.DarkVibrant.hex);
            }
          }
        });
        let interval = setInterval(() => {
          let backgroundWaveSVGColor = document.getElementById("player-background-wave");
          if (backgroundWaveSVGColor) {
            backgroundWaveSVGColor.children[0].children[0].children[1].children[0].children[0].children[0].setAttribute("fill", headingColor);
            clearInterval(interval);
          }
        }, 100);

        // callback();
      });
    }
    // eslint-disable-next-line
  }, [buffer]);

  return {
    sourceLoaded,
    backgroundColor,
    componentBackground,
    headingColor,
    musicBarScrubber,
    bodyColor,
  };
};

export default useProgressiveImage;
