import React, { useState, useRef, useEffect } from "react";
import { Drawer, notification, Slider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCalendarAlt, faExternalLink, faFileSignature, faPause, faPlay, faSignalStream, faSignOut, faStepForward, faThumbsDown, faVolume, faVolumeMute } from "@fortawesome/pro-solid-svg-icons";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { assignStation, getStations, addMessagesToQueue, playerError, setCurrentTrack, setDisableSkip, setEmptyLocation, setIsAssigningStation, setIsPlaying, setMessageQueue, setPlayerVolume, setShowSideMenu, setSongCompletion, calculateFadeArray, checkIfAdvertisement, disconnectPlayer, fadeInVolume, fadeOutVolume, finishedTrack, skipSong } from "../../actions";
import { getDeviceId } from "../../util";
import SideMenu from "../../components/side-menu";
import useProgressiveImage from "./progressiveImage";
import ambii_background from "../../common/images/ambii-background.png";
import axios from "axios";
import PlayerAlreadyConnectedModal from "../../components/player-already-connected-modal";
import DisconnectModal from "../../components/disconnect-modal";
import UncontrolledLottie from "../../components/lottie/uncontrolled-lottie";
import WaterRipple from "../../common/lotties/water-ripple.json";
import DrawerRowItem from "../../components/drawer-row-item";
import PageLoader from "../../components/page-loader";
import MusicScrubberBar from "../../components/music-scrubber-bar";
import DownVoteModal from "../../components/down-vote-modal";
import { responses } from "../../constants";
import { useHistory } from "react-router";
import ambiiLogo from "../../common/images/appLogo.png";
import "./index.less";

const Player = () => {
  //redux-hooks
  const dispatch = useDispatch();
  const { player, myLocation, currentTrack, messageQueue, songCompletion, showSideMenu, mvix } = useSelector((state) => ({
    player: state.player,
    myLocation: state.location,
    currentTrack: state.location.currentTrack,
    messageQueue: state.player.messageQueue,
    songCompletion: state.player.songCompletion,
    showSideMenu: state.player.showSideMenu,
    mvix: state.settings.mvix,
  }));

  // react-hooks
  const history = useHistory();
  const [drawerVisiblity, setDrawerVisiblity] = useState(false);
  // const [showSideMenu, setShowSideMenu] = useState(false);
  const [buffer, setBuffer] = useState(null);
  const [bufferMusic, setBufferMusic] = useState(null);
  const [nextMusic, setNextMusic] = useState(null);
  const [nextBufferImage, setNextBufferImage] = useState(null);
  const [sliderVolume, setSliderVolume] = useState(0.0);
  const [songLoading, setSongLoading] = useState(true);
  const [lastTrack, setLastTrack] = useState(null); // Last track before message mode
  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [messageMode, setMessageMode] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [isMutedBeforeMessages, setIsMutedBeforeMessages] = useState(false);
  const [backgroundAnimationPaused, setBackgroundAnimationPaused] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [showDownVoteModal, setShowDownVoteModal] = useState(false);

  // const [stopPlayPauseAnimation, setStopPlayPauseAnimation] = useState(true);

  let rap = useRef(null);

  const { sourceLoaded, backgroundColor, componentBackground, headingColor } = useProgressiveImage({ buffer });

  // Did Mounts
  useEffect(() => {
    // setPageLoader(true);
    dispatch(calculateFadeArray());
    setSliderVolume(player?.playerVolume);
    setupMessageIntervalChecker();
    dispatch(getStations());
    return () => {
      //this if condition helps in development
      if (process.env.NODE_ENV !== "development") dispatch(setEmptyLocation());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //chrome media session player controls
    try {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.setActionHandler("play", function () {
          if (!songLoading) dispatch(setIsPlaying(true));
        });

        navigator.mediaSession.setActionHandler("pause", function () {
          if (!songLoading) dispatch(setIsPlaying(false));
        });
      }
    } catch (error) {
      console.log("no media session:", error);
    }

    // eslint-disable-next-line
  }, [songLoading, player.skipDisabled]);

  useEffect(() => {
    if (player.isPlaying) {
      playTrack();
      setBackgroundAnimationPaused(false);
    } else {
      setBackgroundAnimationPaused(true);
      try {
        rap.current.audioEl.current.pause();
      } catch (err) {
        console.log("err: ", err);
      }
    }

    if (messageQueue.length > 0 && !lastTrack) {
      let position = 0;

      try {
        position = rap.current.audioEl.current.currentTime;
      } catch (err) {
        console.log("err: ", err);
      }

      //saves the current track
      let trackBeforeMessages = currentTrack;
      if (player.playerVolume === 0.0) setIsMutedBeforeMessages(true);
      else setIsMutedBeforeMessages(false);
      setLastTrack({ ...trackBeforeMessages, position, bufferMusic });
      dispatch(setDisableSkip(true));
      startMessageMode();
      setMessageMode(false);
    }

    if (messageMode && player.isPlaying && !lastTrack && player.playerVolume > 0) {
      dispatch(addMessagesToQueue());
      setMessageMode(false);
    } else setMessageMode(false);
    // eslint-disable-next-line
  }, [player.isPlaying, messageQueue, messageMode]);

  useEffect(() => {
    //Check volume for the track
    dispatch(setSongCompletion(0));
    setBackgroundAnimationPaused(true);
    Promise.all([fetchImage(), fetchMusic()])
      .then(() => {
        dispatch(checkIfAdvertisement(currentTrack));
        setPageLoader(false);
      })
      .catch(() => {
        setPageLoader(false);
        skipCurrentTrack();
      });

    // eslint-disable-next-line
  }, [currentTrack]);

  useEffect(() => {
    if (window.screen.width < 990 && showSideMenu) {
      dispatch(setShowSideMenu(false));
    }
    // eslint-disable-next-line
  }, [window.screen.width, dispatch]);
  useEffect(() => {
    //set buffers to null
    dispatch(setSongCompletion(0));
    setBufferMusic(null);
    setNextMusic(null);
    // eslint-disable-next-line
  }, [myLocation?.station?.name]);

  useEffect(() => {
    if (player.stationToBeAssigned && currentTrack.isMusic) {
      notification.info({ message: "Changing Station", description: "We are changing the station for you!", placement: "bottomLeft", duration: 10 });
      dispatch(assignStation(player.stationToBeAssigned)).then(() => {
        dispatch(setIsAssigningStation(false));
      });
    }
  }, [player.stationToBeAssigned, currentTrack, dispatch]);

  useEffect(() => {
    try {
      fetchNextImage();
      fetchNextTrack();
      if (currentTrack?.position) rap.current.audioEl.current.currentTime = currentTrack?.position;
    } catch (err) {
      console.log("err: ", err);
    }
    // eslint-disable-next-line
  }, [bufferMusic]);

  useEffect(
    (currentTrack, myLocation) => {
      //chrome media session metadata
      try {
        if ("mediaSession" in navigator) {
          navigator.mediaSession.metadata = new window.MediaMetadata({
            title: currentTrack?.title,
            artist: currentTrack?.artist?.name,
            album: myLocation?.station?.name,
            artwork: [
              { src: buffer, sizes: "96x96", type: "image/jpg" },
              { src: buffer, sizes: "128x128", type: "image/jpg" },
              { src: buffer, sizes: "192x192", type: "image/jpg" },
              { src: buffer, sizes: "256x256", type: "image/jpg" },
              { src: buffer, sizes: "384x384", type: "image/jpg" },
              { src: buffer, sizes: "512x512", type: "image/jpg" },
            ],
          });
        }
      } catch (error) {
        console.log("no media session: ", error);
      }
    },
    [buffer]
  );

  const startMessageMode = async () => {
    const fadeVolume = await dispatch(fadeOutVolume());

    if (fadeVolume) {
      dispatch(setCurrentTrack(messageQueue[0]));
      setSongLoading(true);
      dispatch(setPlayerVolume(sliderVolume / 0.6));
    }
  };

  // Starts interval check (on every whole minute, ex: 18:00:00, 18:01:00) for messages to play
  const setupMessageIntervalChecker = () => {
    // Gets any offset within current minute
    let seconds = new Date().getSeconds();
    let secondsUntilMinute = (60 - seconds) * 1000;

    // Runs on exact minute
    let initMessageTimeout = setTimeout(async () => {
      setMessageMode(true);
      // Checks for messages
      setInterval(async () => {
        setMessageMode(true);
      }, 60000);
      clearTimeout(initMessageTimeout);
    }, secondsUntilMinute);
  };

  const fetchMusic = async () => {
    dispatch(setIsPlaying(false));
    if (!nextMusic || bufferMusic === nextMusic || !currentTrack?.isMusic) {
      try {
        const response = await axios.get(currentTrack?.url, { responseType: "arraybuffer" }).catch((err) => {
          if (err) songError(0);
        });

        if (response) {
          let newDataAudio = Buffer.from(response.data, "binary").toString("base64");
          setBufferMusic(`data:audio/mp3;base64,${newDataAudio}`);
        }
      } catch (error) {
        console.log("fetch music error", error);
      }
    }
  };

  const fetchNextTrack = async () => {
    //Save the cancel token for the current request
    if (bufferMusic === null) {
      dispatch(setIsPlaying(false));
      setSongLoading(true);
    }
    const response = await axios.get(get(myLocation, `trackSchedule.queue[${player.trackNumber + 1}].url`, ""), { responseType: "arraybuffer" });
    let newDataAudio = Buffer.from(response.data, "binary").toString("base64");

    setNextMusic(`data:audio/mp3;base64,${newDataAudio}`);
  };

  const fetchImage = async () => {
    try {
      const imageUrl = currentTrack?.isMusic ? currentTrack?.album?.cover_large : currentTrack?.picture;
      const response = await axios.get(imageUrl, { responseType: "arraybuffer" });
      let newData = Buffer.from(response.data, "binary").toString("base64");
      setBuffer(`data:image/jpeg;base64,${newData}`);
    } catch (error) {
      console.log("fetch image error", error);
      setBuffer(ambiiLogo);
    }
  };

  const fetchNextImage = async () => {
    const response = await axios.get(get(myLocation, `trackSchedule.queue[${player.trackNumber + 1}].album.cover_large`, ""), { responseType: "arraybuffer" });
    let newData = Buffer.from(response.data, "binary").toString("base64");
    setNextBufferImage(`data:image/jpeg;base64,${newData}`);
    console.log("next image fetched");
  };

  const songError = (e) => {
    console.log("Song error: ", { e, player, myLocation, currentTrack });
    skipCurrentTrack();
  };

  const playTrack = async () => {
    setSongLoading(false);
    let promise;
    try {
      promise = rap.current.audioEl.current.play();
    } catch (error) {
      console.log(promise);
    }

    // Needed due to https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
    if (promise !== undefined) {
      promise
        .then(() => {
          // Player started autoplaying
          dispatch(setIsPlaying(true));
        })
        .catch(() => {
          // Player blocked from autoplaying
          dispatch(setIsPlaying(false));
        });
    }
  };

  const skipCurrentTrack = async () => {
    const isTrackScheduleEmpty = get(myLocation, "trackSchedule.queue", []).length === 0;

    if (isTrackScheduleEmpty) {
      notification.error({ message: responses.status.error, description: "Something went wrong. Please connect again", placement: "bottomLeft" });
      localStorage.removeItem("ambii_player_jwt");
      history.push("/connect");
    }

    setSongLoading(true);
    if (messageQueue.length > 0) {
      let newQueue = messageQueue;
      newQueue.shift();
      setMessageQueue(newQueue);
      if (messageQueue.length > 0) {
        dispatch(setCurrentTrack(messageQueue[0]));
      } else {
        dispatch(setCurrentTrack(lastTrack));
        setBufferMusic(lastTrack.bufferMusic);
        if (isMutedBeforeMessages) dispatch(setPlayerVolume(0));
        else dispatch(fadeInVolume());
        setLastTrack(null);
        dispatch(setDisableSkip(false));
      }
    } else {
      let trackNumber = get(player, "trackNumber", 0);
      let trackSchedule = get(myLocation, "trackSchedule", "");
      let trackScheduleLength = get(trackSchedule, "queue", []).length - 1;
      let queue = get(trackSchedule, "queue", []);
      let nextTrack = null;
      let queueHash = get(trackSchedule, "queueHash", "");

      dispatch(skipSong({ songCompletion })).then(async (res) => {
        setBufferMusic(nextMusic);
        setNextMusic(null);
        setBuffer(nextBufferImage);
        // Mainly for Safari, as it won't auto-play the next track
        // if (isSafari) {
        //   try {
        //     dispatch(setIsPlaying(!get(player, "isPlaying")));
        //     rap.current.audioEl.current.play();
        //   } catch (err) {
        //     console.log("skipSong err: ", err);
        //   }
        // }
      });

      // Get the next track / track number
      if (trackNumber + 1 <= trackScheduleLength) {
        nextTrack = queue[trackNumber + 1] || "";
        trackNumber += 1;
      } else {
        nextTrack = get(myLocation, "trackSchedule.queue[" + 0 + "]", "");
        trackNumber = 0;
      }

      dispatch(
        finishedTrack({
          locationId: get(myLocation, "_id", null),
          stationId: get(myLocation, "station._id", null),
          scheduleId: get(myLocation, "schedule._id", null),
          nextTrackId: nextTrack?._id,
          lastTrackId: get(currentTrack, "_id", ""),
          deviceId: getDeviceId(),
          songCompletion,
          currentTrackNumber: trackNumber.toString(),
          queueHash,
        })
      ).then(async (res) => {
        if (res === "DEVICE_ALREADY_PAIRED") {
          dispatch(setIsPlaying(false));
          dispatch(setDisableSkip(false));
          dispatch(
            playerError({
              error: true,
              title: "Another Device Has Connected!",
              message: "Please press the 'play' button if you wish to reconnect with this device.",
              playerInfo: {
                deviceName: "web",
                deviceId: getDeviceId(),
                deviceBrand: "web",
                storeCode: myLocation.storeCode,
              },
            })
          );
          await dispatch(disconnectPlayer());
        }
      });
    }
  };

  const changeVolume = (myVolume) => {
    setSliderVolume(myVolume * 0.01 * 0.6);
    dispatch(setPlayerVolume(myVolume * 0.01 * 0.6));
  };

  // Toggles play/pause logic
  const togglePlayPause = (playStatus) => {
    dispatch(setIsPlaying(playStatus));
    // setStopPlayPauseAnimation(playStatus);
  };

  // let hasAlbumCover = currentTrack?.album?.cover_large;
  return (
    <>
      {pageLoader ? (
        <PageLoader text={"Loading Music"} />
      ) : (
        <div className={"player-page-wrapper"}>
          {/* Background Layer  */}
          <div className={"background-wrapper"}>
            <div style={{ backgroundColor: backgroundColor }} className="background-tint" />
            <div style={{ backgroundImage: currentTrack?.isMusic ? `url(${sourceLoaded})` : `url(${ambii_background})` }} className="background-image"></div>
            <div id={"player-background-wave"}>
              <UncontrolledLottie animationData={WaterRipple} isPaused={backgroundAnimationPaused} width={"100vw"} height={"100vh"} speed={0.6} />
            </div>
          </div>

          {/* Foreground Layer */}
          <div className="outer-wrapper">
            <div className="inner-wrapper">
              <div className="connect-header">
                <FontAwesomeIcon onClick={() => setDrawerVisiblity(true)} className="hambuger-menu" style={{ color: componentBackground }} icon={faBars} />
              </div>
              <div className="player-wrapper">
                <div className="artwork-container">
                  <img alt="nothing to show" className={"artwork-image noselect ".concat(!currentTrack?.isMusic ? "ambii-logo" : "")} src={sourceLoaded} />
                </div>
                <div className="player-info-wrapper">
                  <div className="player-station-info-wrapper">
                    <div className={"player-row-elem"}>
                      <span className={"icon"} style={{ color: componentBackground }}>
                        <FontAwesomeIcon icon={faSignalStream} style={{ marginRight: "15px" }} />
                      </span>
                      <span className={"name"} style={{ color: componentBackground }}>
                        {myLocation?.station?.name}
                      </span>
                      {/* <div className={"icon"} /> */}
                    </div>

                    {myLocation?.schedule?.name && (
                      <div className={"player-row-elem"}>
                        <span className={"icon"} style={{ color: componentBackground }}>
                          <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: "15px" }} />
                        </span>
                        <span className={"name"} style={{ color: componentBackground }}>
                          {myLocation?.schedule?.name}
                        </span>
                        {/* <div className={"icon"} /> */}
                      </div>
                    )}
                  </div>
                  <div className="player-title-wrapper">
                    {/* Shows moving title when tilte is too large */}
                    {currentTrack?.title?.length > 50 ? (
                      // eslint-disable-next-line
                      <marquee>
                        <span className={"title text-shadow"} style={{ color: headingColor }}>
                          {currentTrack?.title}
                        </span>
                      </marquee>
                    ) : (
                      <span className={"title text-shadow"} style={{ color: headingColor }}>
                        {currentTrack?.title}
                      </span>
                    )}

                    <span className={"subtitle text-shadow"} style={{ color: componentBackground }}>
                      {currentTrack?.artist?.name || "Announcement"}
                    </span>
                  </div>

                  <MusicScrubberBar color={headingColor} />

                  <div className={"player-control-wrapper"}>
                    <div className={"left-controls"}>
                      <div className="icon-wrapper">
                        {" "}
                        <FontAwesomeIcon icon={faThumbsDown} className={"icon"} style={{ color: componentBackground, opacity: !currentTrack.isMusic && 0.3 }} onClick={() => currentTrack.isMusic && setShowDownVoteModal(true)} />
                      </div>
                    </div>
                    <div className={"center-control"}>
                      <div className="play-pause-button-wrapper">
                        {songLoading && <div className="player-loading-indicator" style={{ borderTopColor: headingColor }} />}
                        <div
                          className="play-pause-button"
                          style={{ backgroundColor: headingColor }}
                          onClick={() => {
                            if (!songLoading) togglePlayPause(!player.isPlaying);
                          }}
                        >
                          {!player.isPlaying ? <FontAwesomeIcon icon={faPlay} style={{ position: "relative", left: "2px" }} /> : <FontAwesomeIcon icon={faPause} />}
                          {/* <ControlledLottie animationData={PlayPauseSVG} isStopped={stopPlayPauseAnimation} width={"125px"} height={"125px"} speed={1} segments={playPauseFrameSegments[!player.isPlaying ? 0 : 1]} /> */}
                        </div>
                      </div>
                    </div>
                    <div className={"right-controls"}>
                      <div className={"icon-wrapper"}>{player.skipDisabled ? <FontAwesomeIcon icon={faStepForward} className="icon" style={{ color: componentBackground, opacity: 0.3, cursor: "default" }} /> : <FontAwesomeIcon icon={faStepForward} className="icon" onClick={() => skipCurrentTrack()} style={{ color: componentBackground }} />}</div>
                      <div className={"icon-wrapper"} id={"volume-wrapper"} onMouseEnter={() => setShowVolumeControl(true)} onMouseLeave={() => setShowVolumeControl(false)}>
                        <FontAwesomeIcon
                          style={{ color: componentBackground }}
                          className="icon"
                          icon={player.playerVolume !== 0 ? faVolume : faVolumeMute}
                          onClick={() => {
                            if (player.playerVolume !== 0) {
                              localStorage.setItem("volume", player.playerVolume);
                              dispatch(setPlayerVolume(0));
                            } else {
                              let localVolume = localStorage.getItem("volume");
                              dispatch(setPlayerVolume(parseFloat(localVolume)));
                            }
                          }}
                        />
                        <div className="volume-slider-box" />

                        {showVolumeControl && <Slider className="volume-slider-bar" onChange={changeVolume} defaultValue={sliderVolume * 0.6 * 100} value={(sliderVolume / 0.6) * 100} handleStyle={{ backgroundColor: headingColor }} trackStyle={{ background: headingColor }} onAfterChange={() => localStorage.setItem("volume", player.playerVolume)} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* React Audio Player */}
          <ReactAudioPlayer
            listenInterval={400}
            ref={rap}
            src={bufferMusic}
            autoPlay={false}
            onCanPlay={() => playTrack()}
            onEnded={() => skipCurrentTrack()}
            onError={(e) => songError(e)}
            volume={player.playerVolume}
            preload={"auto"}
            muted={player.playerVolume === 0 ? true : false}
            onListen={(e) => {
              const songCompleted = parseFloat(((e / currentTrack.duration) * 100).toFixed(2));
              dispatch(setSongCompletion(songCompleted));
            }}
          />

          <Drawer width={window.innerWidth <= 990 ? "75%" : "30%"} placement="left" closable={false} onClose={() => setDrawerVisiblity(false)} visible={drawerVisiblity} push={false} key="left" className="drawer-wrapper">
            <div className="drawer-header">
              <div className={"sidebar-hamburger-wrapper"}>
                <FontAwesomeIcon
                  icon={faBars}
                  className="hamburger-icon"
                  onClick={() => {
                    setDrawerVisiblity(false);
                    dispatch(setShowSideMenu(false));
                  }}
                />
              </div>
              <div className="drawer-header-content">
                <span className={"title noselect"}>Playing At</span>
                <span className={"location-name noselect"}>{myLocation.name}</span>
                <span className={"store-code"}>{myLocation.storeCode}</span>
              </div>
            </div>
            <div className="drawer-content">
              <div className={"top-section"}>
                {/* <DrawerRowItem icon={darkTheme ? faMoon : faSun} title={darkTheme ? "Dark" : "Light"} extra={<Switch className="drawer-icon" defaultChecked={darkTheme} onChange={(checked) => dispatch(setDarkThemeForApp(checked))} />} /> */}
                {get(myLocation, "allowPlayerToChangeStation", false) && <DrawerRowItem icon={faSignalStream} title={"Stations"} onClick={() => dispatch(setShowSideMenu(!showSideMenu))} />}
              </div>
              <div className={"bottom-section"}>
                <DrawerRowItem icon={faSignOut} title={"Disconnect"} onClick={() => setShowDisconnectModal(true)} />
                <DrawerRowItem icon={faExternalLink} title={"Go To Admin Panel"} onClick={() => window.open(mvix ? "https://mvix.ambii.io" : "https://business.ambii.io", "_blank")} iconStyle={{ fontSize: "medium" }} titleStyle={{ fontSize: "medium" }} className={"bottom-elem"} />
                {/* <DrawerRowItem icon={faFileSignature} title={`${new Date().getFullYear()} Copyright, Terms and Services`} onClick={() => () => window.open("https://business.ambii.io", "_blank")} iconStyle={{ fontSize: "medium" }} titleStyle={{ fontSize: "medium" }} className={"bottom-elem"} /> */}
              </div>
            </div>
          </Drawer>
          {showSideMenu && window.screen.width > 990 && <SideMenu setDrawerVisiblity={setDrawerVisiblity} />}
          {showSideMenu && window.screen.width <= 990 && console.log("Mobile menu")}
          <PlayerAlreadyConnectedModal />
          <DisconnectModal visible={showDisconnectModal} setVisible={(e) => setShowDisconnectModal(e)} />
          <DownVoteModal visible={showDownVoteModal} setVisible={setShowDownVoteModal} />
        </div>
      )}
    </>
  );
};

export default Player;
