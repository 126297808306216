import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StationTile from "../station-tile";
import { Button } from "antd";
import { getStations } from "../../actions";
import "./index.less";

const SchedulesAndStaions = ({ setDrawerVisiblity }) => {
  //redux-hooks
  const { stations, locationId, favorites } = useSelector((state) => ({
    stations: state.stations,
    locationId: state.location._id,
    favorites: state.favorites,
  }));

  const dispatch = useDispatch();

  //react hooks
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(false);

  //functions
  const loadMoreHandler = async () => {
    setLoading(true);
    await dispatch(getStations(page));
    setPage((prevPage) => prevPage + 1);
    setLoading(false);
  };
  return (
    <div className="schedules-and-staions">
      {/* <div className="heading" id="schedules">
        <h2>Your Schedules</h2>
      </div>
      <div>
        <Button className="menu-items">Schedule 1</Button>
        <Button className="menu-items">Schedule 2</Button>
        <Button className="menu-items">Schedule 3</Button>
      </div> */}
      {favorites.length > 0 && (
        <>
          <div className="heading" id="favourite">
            <h2>Favorites</h2>
          </div>
          <div>
            {favorites.map((station) => (
              <StationTile key={station._id} name={station.name} stationId={station._id} locationIds={[locationId]} setDrawerVisiblity={setDrawerVisiblity} />
            ))}
          </div>
        </>
      )}

      <>
        <div className="heading" id="stations">
          <h2>Stations</h2>
        </div>
        <div className="station-tile-wrapper">
          {stations.map((station, index) => (
            <StationTile key={index} name={station.name} stationId={station._id} locationIds={[locationId]} setDrawerVisiblity={setDrawerVisiblity} />
            // <Button className="menu-items">{station.name.length > 16 ? station.name.slice(0, 10) + "..." : station.name}</Button>
          ))}
        </div>
      </>
      <div className="bottom-row">
        <Button size="large" className="load-more-button" onClick={loadMoreHandler} loading={loading}>
          Load More
        </Button>
      </div>
    </div>
  );
};
export default React.memo(SchedulesAndStaions);
