import React from "react";
import { useSelector } from "react-redux";
import "./index.less";
const MusicScrubberBar = ({ color }) => {
  const songCompletion = useSelector((state) => state.player.songCompletion);
  return (
    <div className="music-bar-scrub-wrapper">
      <div className="music-bar-scrubber" style={{ width: songCompletion + "%", background: color }} />
    </div>
  );
};

export default React.memo(MusicScrubberBar);
