import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.less";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./redux-store/storeConfiguration";
import RootContainer from "./containers/RootContainer";
import { PersistGate } from "redux-persist/integration/react";

if (document.location.hostname !== "localhost" && document.location.hostname !== "player-dev.ambii.io") console.log = () => {};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <RootContainer />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
