import { HTTP } from "../configs";
import { logError, fadeOutVolume, getAnnouncements, setCurrentTrack, setDisableSkip, setIsAssigningStation, setLocation, setPlayerVolume, setStations, setStationToBeAssigned, setTrackMap, setTrackNumber, setTrackSchedule } from "./";
import { get } from "lodash";
import { notification } from "antd";
import { responses } from "../constants";
import { objArrayToMap } from "../util";

export function getStations(page = 1) {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      HTTP("get", `/v3/api/station?page=${page}`)
        .then((response) => {
          if (get(response, "success", false)) {
            let data = get(response, "data", {});

            const prevStations = getState().stations;
            // let stationMap = {};
            const withNewStations = page === 1 ? data : [...prevStations, ...data];
            // data.forEach((station) => (stationMap[`${station?._id}`] = station));
            dispatch(setStations(withNewStations));
          } else notification.error({ message: responses.status.error, description: get(response, "message", responses.status.error), placement: "bottomLeft" });
          resolve(true);
        })
        .catch((err) => {
          dispatch(logError({ name: "getStations", err }));
          notification.error({ message: responses.status.error, description: responses.description.internalServerError, placement: "bottomLeft" });
          reject(err);
        });
    });
  };
}

export const assignStation =
  ({ stationId, locationIds }) =>
  (dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch(setIsAssigningStation(true));
        let response = await HTTP("post", `/v3/api/station/${stationId}`, { locations: locationIds });

        if (get(response, "success", false)) {
          let data = get(response, "data", {});

          const playerVolume = getState().player.playerVolume;
          const isMusic = getState().location.currentTrack.isMusic;
          const calcVolume = !isMusic ? playerVolume * 0.6 : playerVolume;
          let location = get(data, "locations[0]", {});
          let trackSchedule = get(location, "trackSchedule", {});
          let trackMap = objArrayToMap(get(trackSchedule, "queue", []), "isrc");
          let currentTrack = get(location, "currentTrack", null); // Get the currentTrackNumber from server
          let currentTrackNumber = get(location, "currentTrackNumber", 0); // Get the currentTrackNumber from server
          let currentTrackByNumber = get(trackSchedule, "queue[" + currentTrackNumber + "]", currentTrack); // Get the currentTrack by number

          dispatch(setStationToBeAssigned(null));
          // Dispatch parent data first
          dispatch(setLocation(location)); // Save location updates
          dispatch(getAnnouncements());
          dispatch(setTrackMap(trackMap)); // Save track map
          dispatch(setTrackNumber(currentTrackNumber)); // Save track map
          dispatch(setTrackSchedule(trackSchedule)); // Set Track Schedule
          await dispatch(fadeOutVolume());
          dispatch(setCurrentTrack(currentTrackByNumber));
          dispatch(setPlayerVolume(calcVolume));
          dispatch(setDisableSkip(true));
          // dispatch(setShowSideMenu(false));
          notification.destroy();
          notification.success({ message: get(response, "message", responses.status.success), description: "Station has been assigned", placement: "bottomLeft" });
          resolve(data);
        }
      } catch (err) {
        notification.error({ message: "Error", description: get(err, "response.data.message", false), placement: "bottomLeft" });
        reject(err);
      }
    });
