//prettier-ignore
import { SET_MVIX, SET_SCHEDULE, ADD_TO_TRACK_MAP, SET_MESSAGES, SET_MESSAGE_HASH, SET_INTERVAL_MESSAGES_MAP, SET_SCHEDULED_MESSAGES_MAP, SET_STATIONS, PLAYER_ERROR, SET_LOCATION, SET_TRACK_NUMBER, SET_SHOW_PLAYER, SET_IS_PLAYING, SET_TRACK_SCHEDULE, SET_LAST_TRACK, SET_TRACK_MAP, SET_STATION, SET_CURRENT_TRACK, SET_SKIP_DISABLED, SET_PLAYER_VOLUME, SET_MESSAGE_QUEUE, SET_FADE_ARRAY, SET_FADE_ARRAY_INDEXES, SET_SONG_COMPLETION, SET_EMPTY_LOCATION, SET_IS_ASSIGNING_STATION, SET_SHOW_SIDE_MENU, SET_FAVORITES, SET_STATION_TO_BE_ASSIGNED } from "../constants";

export const setTrackSchedule = (data) => (dispatch) => {
  dispatch({
    type: SET_TRACK_SCHEDULE,
    data,
  });
};

export const setLastTrack = (data) => (dispatch) => {
  dispatch({
    type: SET_LAST_TRACK,
    data,
  });
};

export const setTrackMap = (data) => (dispatch) => {
  dispatch({
    type: SET_TRACK_MAP,
    data,
  });
};

export const setLocation = (data) => (dispatch) => {
  dispatch({
    type: SET_LOCATION,
    data,
  });
};

export const setStation = (data) => (dispatch) => {
  dispatch({
    type: SET_STATION,
    data,
  });
};

export const setSchedule = (data) => (dispatch) => {
  dispatch({
    type: SET_SCHEDULE,
    data,
  });
};

export const setCurrentTrack = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_TRACK,
    data,
  });
};

export const playerError = (data) => (dispatch) => {
  dispatch({
    type: PLAYER_ERROR,
    data,
  });
};

export const setMVIX = (data) => (dispatch) => {
  dispatch({
    type: SET_MVIX,
    data,
  });
};

export const setDarkTheme = (data) => (dispatch) => {
  dispatch({
    type: "SET_DARK_THEME",
    data,
  });
};

export const showPlayer = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOW_PLAYER,
    data,
  });
};

export const setIsPlaying = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_PLAYING,
    data,
  });
};

export const setDisableSkip = (data) => (dispatch) => {
  dispatch({
    type: SET_SKIP_DISABLED,
    data,
  });
};

export const setTrackNumber = (data) => (dispatch) => {
  dispatch({
    type: SET_TRACK_NUMBER,
    data,
  });
};

export const setStations = (data) => (dispatch) => {
  dispatch({
    type: SET_STATIONS,
    data,
  });
};

export const setPlayerVolume = (data) => (dispatch) => {
  dispatch({
    type: SET_PLAYER_VOLUME,
    data,
  });
};

export function setMessages(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_MESSAGES,
      data,
    });
  };
}

export function setMessageHash(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_MESSAGE_HASH,
      data,
    });
  };
}

export function setScheduledMessagesMap(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_SCHEDULED_MESSAGES_MAP,
      data,
    });
  };
}

export function setIntervalMessagesMap(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_INTERVAL_MESSAGES_MAP,
      data,
    });
  };
}

export function addToTrackMap(data) {
  return async (dispatch) => {
    dispatch({
      type: ADD_TO_TRACK_MAP,
      data,
    });
  };
}

export function setMessageQueue(data) {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGE_QUEUE,
      data,
    });
  };
}

export const setFadeArray = (data) => ({ type: SET_FADE_ARRAY, data });
export const setFadeArrayIndexes = (data) => ({ type: SET_FADE_ARRAY_INDEXES, data });
export const setSongCompletion = (data) => ({ type: SET_SONG_COMPLETION, data });
export const setEmptyLocation = () => ({ type: SET_EMPTY_LOCATION });
export const setIsAssigningStation = (data) => ({ type: SET_IS_ASSIGNING_STATION, data });
export const setShowSideMenu = (data) => ({ type: SET_SHOW_SIDE_MENU, data });
export const setFavorites = (data) => ({ type: SET_FAVORITES, data });
export const setStationToBeAssigned = (data) => ({ type: SET_STATION_TO_BE_ASSIGNED, data });
