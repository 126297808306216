export const responses = {
  // Statuses
  status: {
    success: "Success",
    error: "Error",
    warn: "Notice",
  },

  // User Action Feedback
  action: {
    connected: "Player Connected",
    disconnected: "Player Disconnected",
  },

  // General Descriptions
  description: {
    connected: "Player is now connected",
    disconnected: "Player was disconnected",
    failedToConnect: "Player failed to connect",
    failedToDisconnect: "Player failed to disconnect",
    failedToGetAnnouncements: "There was an issue when retrieving your announcements",
    pleaseReconnect: "Please re-connect with your store code",

    // General Errors
    internalServerError: "A server error occurred. Please refresh and try again.",
    requiredDataNotFound: "Required data not found.",
    failedToRetrieveData: "Could not retrieve data. Please refresh and try again.",
  },
};
