import { get } from "lodash";

export const tagTypes = {
  explicit: { name: "Explicit", color: "#EA526F" },
};

export const capitolizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.substring(1, str.length);
};

export const generateRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result.toUpperCase();
};

/**
 * Converts an object-array to map
 * @param {*} arr - Array to convert
 * @param {*} key - Object key
 */
export const objArrayToMap = (arr, key) => {
  if (!arr || !key) return [];
  let map = [];
  arr.map((item) => {
    return (map[get(item, key, null)] = item);
  });
  return map;
};

// Convert array to map
export const arrayToMap = (arr) => {
  if (!arr) return [];
  let map = [];
  arr.map((item) => {
    return (map[item] = true);
  });
  return map;
};

export const hashCode = (s) => {
  if (!s) return "";
  return "https://random-station-artwork.s3-us-west-2.amazonaws.com/" + (Math.abs(s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0)) % 101) + ".jpg";
};

// Check screen width for mobile dimensions
export const checkForMobile = () => {
  let width = window.outerWidth;
  if (width <= 650) return true;
  return false;
};

// Converts seconds into "0:13" time format
export const getTime = (time) => {
  if (!isNaN(time)) return Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2);
  else return "0:00";
};

// Creates, sets, and returns a deviceId
export const getDeviceId = () => {
  let deviceId = localStorage.getItem("deviceId"); // Get previously generated deviceId (if any)
  if (!deviceId) {
    deviceId = "web-" + generateRandomString(10); // If no deviceId, create one
    localStorage.setItem("deviceId", deviceId); // Store deviceId
  }
  return deviceId;
};

export const getTrackNumberByTrack = (track, arr) => {
  try {
    const advertisement = get(track, "isrc", "").substring(0, 7) === "ADVERT_";
    const ism = get(track, "isrc", "").substring(0, 4) === "ISM_";

    if (advertisement || ism) return false;

    for (let i = 0; i < arr.length; i++) if (get(arr[i], "isrc", "") === get(track, "isrc", track)) return i;

    return 0;
  } catch (err) {
    console.log("caught getTrackNumberByTrack err: ", err);
    return false;
  }
};

// Creates a date string for scheduled message comparison
export const dateFormatter = (incomingDate, isDev) => {
  try {
    let date = incomingDate ? incomingDate : new Date();
    let day = date.toString().toLowerCase().slice(0, 3);
    let time = date.toString().toLowerCase().slice(16, 21);

    // Start Dev - Will schedule messages 2 minutes after init
    if (isDev) {
      let hour = date.getHours();
      let minute = date.getMinutes();
      minute = minute < 59 ? (minute += 1) : (minute = 1); // Dev Use

      if (hour < 10) {
        hour = hour.toString();
        hour = "0" + hour;
      }

      if (minute < 10) {
        minute = minute.toString();
        minute = "0" + minute;
      }
      return day.toLowerCase().concat(":").concat(hour.toString()).concat(":").concat(minute.toString());
    }
    // End Dev - Will schedule messages 2 minutes after init

    return day.toLowerCase().concat(":").concat(time);
  } catch (e) {
    console.log("Error when formatting date");
    return "";
  }
};

// Creates a date string for scheduled message comparison
export const messageDateFormatter = ({ days, time }) => {
  try {
    return days.map((day) => day.toLowerCase().concat(":").concat(time));
  } catch (e) {
    console.log("Error on messageDateFormatter");
    return [];
  }
};

export const getQuadraticRoots = (a, b, c) => {
  try {
    if ((!a && a !== 0) || (!b && b !== 0) || (!c && c !== 0)) return 1;
    let discriminant = b * b - 4 * a * c; // calculate discriminant
    if (discriminant > 0) return (-b + Math.sqrt(discriminant)) / (2 * a);
    // condition for real and different roots
    else if (discriminant === 0) return -b / (2 * a);
    // condition for real and equal roots
    else {
      // if roots are not real
      let realPart = (-b / (2 * a)).toFixed(2);
      let imagPart = (Math.sqrt(-discriminant) / (2 * a)).toFixed(2);
      return realPart + imagPart;
    }
  } catch (e) {
    console.log("Error on getQuadraticRoots");
    return 1;
  }
};
