// import { SET_DARK_THEME } from "../constants";
import initialState from "../redux-store/initialState";

const darkTheme_reducer = (state = initialState.darkTheme, action) => {
  switch (action.type) {
    case "SET_DARK_THEME":
      return Object.assign({}, state, { darkTheme: action.data });
    default:
      return state;
  }
};

export default darkTheme_reducer;
