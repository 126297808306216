/* eslint-disable no-undef */
import { envTypes } from "../constants";
const env = process.env.REACT_APP_STAGE;

// Export config variables
export const STRIPE_KEY = env === envTypes.prod ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV;
export const AUTH0_KEY = env === envTypes.prod ? process.env.REACT_APP_AUTH0HELPER_KEY_PROD : process.env.REACT_APP_AUTH0HELPER_KEY_DEV;
export const API_URL = env === envTypes.prod ? process.env.REACT_APP_API_URL_PROD : env === envTypes.dev ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LOCAL;
export const APP_TITLE = env === envTypes.prod ? process.env.REACT_APP_APP_TITLE_PROD : env === envTypes.dev ? process.env.REACT_APP_APP_TITLE_DEV : process.env.REACT_APP_APP_TITLE_LOCAL;
export const AMBII_API_KEY = env === envTypes.prod ? process.env.REACT_APP_AMBII_API_KEY_PROD : process.env.REACT_APP_AMBII_API_KEY_DEV;
export const version = "1.0.0";

// Export variables from other config files
export * from "./api";
export * from "./google-analytics";
