import { setDarkTheme } from "./";
import { colors } from "../styles/colors";

const handleSwitchChange = (data) => {
  // less variables that will be used here must be declared in themeVariables on config-overrides.js
  // data => darktheme => true
  window.less
    .modifyVars({
      "@primary-color": data ? colors.pink : colors.pink,
      "@heading-color": data ? colors.white : colors.black,
      "@text-color": data ? colors.white : colors.black,
      "@text-color-secondary": "white",
      "@body-background": data ? colors.deepPurple : colors.offWhite,
      "@component-background": data ? colors.deepPurple : colors.white,
      "@input-placeholder-color": data ? colors.opaqueWhite : colors.opaquteBlack,
      "@border-color-inverse": "red",
      "@border-color-base": data ? colors.white : colors.black, // Button border color
      "@border-color-split": data ? colors.deepPurple : colors.white, // Modal inner border color
    })
    .then(() => {
      data ? localStorage.setItem("darkTheme", true) : localStorage.setItem("darkTheme", false);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const setDarkThemeForApp = (data) => {
  return (dispatch) => {
    handleSwitchChange(data);
    dispatch(setDarkTheme(data));
  };
};
