import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "antd";
import { playerError, connectPlayer } from "../../actions";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { colors } from "../../styles/colors";

const PlayerAlreadyConnectedModal = () => {
  const dispatch = useDispatch();
  const playerErr = useSelector((state) => state.player.playerError);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const cancelHandler = () => {
    setLoading(false);
    dispatch(playerError({ error: false }));
    localStorage.removeItem("ambii_player_jwt");
    history.push("/connect");
  };

  return (
    <Modal
      title={
        <>
          <ExclamationCircleOutlined style={{ color: colors.warningColor, marginRight: "10px" }} /> Device Already Connected!
        </>
      }
      visible={playerErr?.error}
      className={"custom-modal-wrapper"}
      footer={[
        <Button key="cancel" onClick={cancelHandler}>
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            dispatch(connectPlayer({ ...playerErr.playerInfo, override: true })).then(() => {
              dispatch(playerError({ error: false }));
              history.push(`/${playerErr.playerInfo.storeCode}`);
            });
            Modal.destroyAll();
          }}
        >
          Yes, Connect
        </Button>,
      ]}
    >
      Another device is already connected to this account. Would you like to play music on this device instead?
    </Modal>
  );
};

export default PlayerAlreadyConnectedModal;
