import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowSideMenu } from "../../actions";
import SchedulesAndStations from "../schedules-and-stations";
import "./index.less";

const SideMenu = ({ setDrawerVisiblity }) => {
  const darkTheme = useSelector((state) => state.darkTheme.darkTheme);
  const dispatch = useDispatch();
  return (
    <div className="side-menu-wrapper">
      <div
        className="side-menu-content"
        onClick={(e) => {
          //if clicked outside close the side-menu
          if (e.target.className === "station-tile-wrapper" || e.target.className === "side-menu-content") {
            dispatch(setShowSideMenu(false));
          }
        }}
        // onScroll={() => {
        //   // const a = document.querySelector(".side-menu-wrapper");
        //   const headings = document.querySelectorAll(".heading");
        //   let current = "";
        //   headings.forEach((heading) => {
        //     const headingTop = heading.offsetTop;
        //     // const headingHeight = heading.clientHeight;
        //     if (headingTop === 40) {
        //       current = heading.getAttribute("id");
        //     }
        //     if (headingTop === 253) {
        //       current = heading.getAttribute("id");
        //     }
        //     if (headingTop === 606) {
        //       current = heading.getAttribute("id");
        //     }
        //   });
        //   console.log(current);
        // }}
      >
        <SchedulesAndStations setDrawerVisiblity={setDrawerVisiblity} />
      </div>
      <div
        className="background-opacity-layer"
        style={{
          backgroundColor: darkTheme ? "#1c1521" : "#717171",
          width: "70vw",
        }}
      ></div>
    </div>
  );
};
export default React.memo(SideMenu);
